import { PagingEvent } from './../data-table-wrapper/models';
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { DataTableFilter, DataTableFilterOption, DataTableRowClickCallback, DataTableRowTrackByCallback, DATATABLE_STORE_PREFIX } from "../models";
import { AbstractControl, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { OverThen } from '../data-table-wrapper/validators';
import { SortBy } from '../utils';
import { AppInjector } from 'app/core/app-injector';
import { SiteSelectorService } from 'app/core/services/site.service';
import * as _ from 'lodash';
import { PaginatorPagingEvent } from '../data-table-paginator/models';
import { DATATABLE_DEFAULT_OPTIONS } from '../data-table.module';

/**
 * text         - display string
 * date         - display date in the format 'yyyy-MM-dd'
 * datetime     - display date and time in the format 'yyyy-MM-dd HH:ii:ss'
 * button       - display button
 * number       - display numbers in comma format
 * panel        - expandable panel, need provide a template to display collapsed content
 * link         - link button
 */
export interface DataTableColumn<T> {
    type?: 'text' | 'date' | 'datetime' | 'button' | 'number' | 'panel' | 'link';
    name: string;
    label: string;
    format?: string;
    width?: string;
    button_label?: string;
    panelOpen?: boolean;
    panelClass?: string;
    headerPanelClass?: string;
    description?: string;
    disableHideColumn?: boolean;
    link?: DataTableLinkCallback<T>;
    callback?: DataTableButtonCallback<T>;
}

export type DataTableButtonCallback<T> = (data: T, index:number, define: DataTableColumn<T>) => void;
export type DataTableRowSelectedCallback<T> = (row: T) => (r: T) => boolean;
export type DataTableLinkCallback<T> = (row: T) => string | Array<any>;
export type IsPanelOpenCallback<T> = (target: T) => (each: T) => boolean;

export class DataTable<T> {

    // Name used for local storage
    storeName: string = '';

    // Limit - items per page
    _limit: number = 10;
    get limit(): number { return this._limit; }
    set limit(limit: number) {
        this._limit = limit;
        this.lastPaginatorEvent.limit = limit;
        this.lastPaginatorEvent.offset = this.lastPaginatorEvent.limit * (this.lastPaginatorEvent.page - 1);
        this.lastPagingEvent.limit = this.lastPaginatorEvent.limit;
        this.lastPagingEvent.offset = this.lastPaginatorEvent.offset;
        this.changeSubject.next(this);
    }

    // Page - current page
    _page: number = 1;
    get page(): number { return this._page; }
    set page(page: number) {
        this._page = page;
        this.lastPaginatorEvent.page = page;
        this.lastPaginatorEvent.offset = this.lastPaginatorEvent.limit * (this.lastPaginatorEvent.page - 1);
        this.lastPagingEvent.page = this.lastPaginatorEvent.page;
        this.lastPagingEvent.offset = this.lastPaginatorEvent.offset;
        this.changeSubject.next(this);
    }

    // Columns - the all columns of this table
    private _columns: Array<DataTableColumn<T>> = [];
    get columns(): Array<DataTableColumn<T>> { return this._columns };
    set columns(value) {
        this._columns = value;
        this.displayCols = this.displayCols.filter( col => this.columns.findIndex( c => c.name === col ) >= 0 );
        this.changeSubject.next(this);
    };

    // Filters
    public _defaultDisplayFilters: Array<string> = [];
    _display_filters: boolean;
    get display_filters(): boolean { return this._display_filters; }
    set display_filters(display: boolean) { this._display_filters = display; this.storeTableConfig(); }
    private _filters: Array<DataTableFilter> = [];
    get filters(): Array<DataTableFilter> { return this._filters; }
    set filters(value: Array<DataTableFilter>) {
        this._filters = value;
        this.show_filters = value.map(f => f.name).filter(f => this.show_filters.indexOf(f) >= 0);
        this.changeSubject.next(this);
    };
    _show_filters: Array<string> = [];
    get show_filters(): Array<string> { return this._show_filters; }
    set show_filters(arr: Array<string>) {
        this._show_filters = arr;
        this.show_filters_cols = this.filters
                                    .filter(f => arr.indexOf(f.name) >= 0)
                                    .map(f => f.cols || 1)
                                    .reduce((a,b) => a+b, 0);
        this.changeSubject.next(this);
        this.storeTableConfig();
    }
    show_filters_cols: number = 0;

    // DisplayCols - display columns
	public _defaultDisplayCols: Array<string> = [];
    private _displayCols: Array<string> = [];
    get displayCols(): Array<string> { return this._displayCols; }
    set displayCols(value: Array<string>) {
        this._displayCols = value;
        this.cols = this.columns.filter( c => value.indexOf( c.name ) >= 0 );
        this.changeSubject.next(this);
        this.storeTableConfig();
    };

    // Data - data rows
    private _data: Array<T> = [];
    get data(): Array<T> { return this._data; }
    set data(arr: Array<T>) { this._data = arr; this.changeSubject.next(this); }

    // Changes
    public changeSubject = new Subject<DataTable<T>>();
    public change$ = this.changeSubject.asObservable();


    /**
     * Columns Should Display
     */
    cols: Array<DataTableColumn<T>> = [];

    // Row
    row_click_event: DataTableRowClickCallback<T> = null;

    // Total - total rows
    private _total: number = null;
    get total(): number { return this._total; }
    set total(total: number) { this._total = total; this.changeSubject.next(this); }

    // Paging Event
    lastPaginatorEvent: PaginatorPagingEvent = { limit: 10, page: 1, offset: 0 } as PaginatorPagingEvent;
    lastPagingEvent: PagingEvent<any> = { limit: 10, page: 1, offset: 0, query: '', filters: {} } as PagingEvent<any>;

    // FilterForm
    _form: UntypedFormGroup = null;
    get form() { return this._form; }

    // TrackBy Function - tune peroformance
	public trackBy?: DataTableRowTrackByCallback<T> = null;

    // Sort
    // public sort?: DataTableSort = null;
    // public sortEvent: Subject<DataTableSort> = new Subject();

    // Panel
    public isPanelOpenCallback: IsPanelOpenCallback<T> = null;

    // private selectedRows: Array<T> = [];
    private _selectable: boolean = false;
    public get selectable(): boolean { return this._selectable; };
    public set selectable(v: boolean) { this._selectable = v; this.changeSubject.next(this); };
	private selectedRowsSubject: BehaviorSubject<Array<T>> = new BehaviorSubject([]);
	public selectedRows$: Observable<Array<T>> = this.selectedRowsSubject.asObservable();
    public isSelected?: DataTableRowSelectedCallback<T>;

    // Focusable
    private _focusable: boolean = false;
    public get focusable(): boolean { return this._focusable; };
    public set focusable(v: boolean) { this._focusable = v; this.changeSubject.next(this); };
    private focusRowSubject: BehaviorSubject<T> = new BehaviorSubject(null);
	public focusRow$: Observable<T> = this.focusRowSubject.asObservable();
    get focusRow(): T { return this.focusRowSubject.value; }
    set focusRow(row: T) { this.focusRowSubject.next( row ); }

    constructor(config: {
        display_filters?: boolean,
        storeName?: string,
        columns?: Array<DataTableColumn<T>>,
        filters?: Array<DataTableFilter>,
        show_filters?: Array<string>,
        displayColumns?: Array<string>,
        data?: Array<T>,
        isSelected?: DataTableRowSelectedCallback<T>,
        total?: number,
        limit?: number,
        page?: number,
        // sort?: DataTableSort,
		trackBy?: DataTableRowTrackByCallback<T>,
        rowClickEvent?: DataTableRowClickCallback<T>,
        focusable?: boolean,
        selectable?: boolean,
        isPanelOpenCallback?: IsPanelOpenCallback<T>,
    }) {
        const default_options = AppInjector.getInjector().get(DATATABLE_DEFAULT_OPTIONS);

        // Set Default Values
        this.display_filters = (config.display_filters === undefined ? true : config.display_filters) || default_options.wrapper.ShowFilters;
        this.total = config.total || 0;
        this.limit = config.limit || default_options.paginator.Limit;
        this.page = config.page || 1;
        // this.sort = config.sort || null;
        this.isSelected = config.isSelected || undefined;

        if( config.columns ) { this.columns = config.columns || []; }
        if( config.filters ) { this.filters = config.filters || []; }
        if( config.data    ) { this.data = config.data || [];       }
		if( config.trackBy ) { this.trackBy = config.trackBy;       }
        if( config.rowClickEvent ) { this.row_click_event = config.rowClickEvent; }
        if( config.focusable ) { this.focusable = config.focusable !== undefined ? config.focusable : default_options.table.Focusable; }
        if( config.selectable ) { this.selectable = config.selectable !== undefined ? config.selectable : false; }
        if( config.isPanelOpenCallback ) { this.isPanelOpenCallback = config.isPanelOpenCallback; }

        // Cols
		this._defaultDisplayCols = config.displayColumns || (config.columns && config.columns.map( col => col.name )) || [];
        this.displayCols = config.displayColumns || (config.columns && config.columns.map( col => col.name )) || [];

        // Filters
        this._defaultDisplayFilters = config.show_filters || (config.filters && config.filters.map(f => f.name)) || [];
        this.show_filters = config.show_filters || (config.filters && config.filters.map( f => f.name )) || [];

        // Restore the last config
        if( config.storeName ) {
            this.restoreTableConfig(config.storeName);
            this.storeName = config.storeName || '';
        }

        // Initialize
        this.initForm();
        this.lastPagingEvent = {
            limit: this.limit,
            page: this.page,
            offset: this.limit * (this.page - 1),
            query: this.objectToQuery(this.getFilters()),
            filters: this.getFilters(),
        } as PagingEvent<any>;
    }

    public clearSelectedRows(): void {
        // this.selectedRows = [];
		this.selectedRowsSubject.next([]);
        this.changeSubject.next(this);
    }

    public isSelectedRow(row: T): boolean {
		if( this.isSelected ) {
            return !!this.selectedRowsSubject.value.find( this.isSelected(row) );
        } else {
            return this.selectedRowsSubject.value.indexOf( row ) >= 0;
        }
    }

    public getSelectedRows(): Array<T> {
        // return this.selectedRows;
        return this.selectedRowsSubject.value;
    }

    public addSelectedRow(row: T): void {
		if( this.isSelected && !this.selectedRowsSubject.value.find( this.isSelected(row) ) ) {
            this.selectedRowsSubject.next([ ...this.selectedRowsSubject.value, row ]);
        }
        this.changeSubject.next(this);
    }

    public removeSelectedRow(row: T): void {
        if( this.isSelected && this.selectedRowsSubject.value.find( this.isSelected(row) ) ) {
            const idx = this.selectedRowsSubject.value.findIndex( this.isSelected(row) );
            const arr = this.selectedRowsSubject.value;
			arr.splice(idx, 1);
			this.selectedRowsSubject.next(arr);
        }
        this.changeSubject.next(this);
    }

    public initForm(): void {
        const service = AppInjector.getInjector().get(SiteSelectorService);
        const form_controls: {[key: string]: AbstractControl} = {};
        this.filters.forEach((i, index) => {
            if( i.type === 'date-range' ) {
                const group = new UntypedFormGroup({
                    'from': new UntypedFormControl(i.defaultValue?.from || null),
                    'to': new UntypedFormControl(i.defaultValue?.to || null, OverThen('from')),
                });
                form_controls[i.name] = group;
                form_controls[i.name].valueChanges.subscribe(v => {
                    form_controls[i.name].get('from').updateValueAndValidity({ onlySelf: true });
                    form_controls[i.name].get('to').updateValueAndValidity({ onlySelf: true });
                })
                return;
            }

            if( i.type === 'webtag' ) {
                form_controls[i.name] = service.WebtagControl;
                return
            }
            if( i.type === 'site_group' ) {
                form_controls[i.name] = service.SiteGroupControl;
                return
            }
            if( i.type === 'sites' ) {
                form_controls[i.name] = service.SiteControlMultiple;
                return
            }
            if( i.type === 'equipment' ) {
                form_controls[i.name] = service.EquipmentControl;
                return
            }

            if( i.options && !i.doNotSortOptions ) {
                this.filters[index].options = i.options.sort( SortBy('label') )
            }

            const fc = new UntypedFormControl(i.defaultValue, []);
            if( i.disabled !== undefined || i.readonly !== undefined ) {
                if( i.disabled !== undefined && i.disabled || i.readonly !== undefined && i.readonly ) {
                    fc.disable();
                }
            }
            form_controls[i.name] = fc;
        });

        this._form = new UntypedFormGroup( form_controls );
        this.form.valueChanges.subscribe( v => {
            this.lastPagingEvent.filters = this.getFilters();
            this.lastPagingEvent.query = this.objectToQuery(this.lastPagingEvent.filters);
        });
    }

    public getFilters(): {[key: string]: any} {
        let f = this.form.value;

        if( !f ) { return {}; }

        Object.keys(f).forEach(key => {
            if( f[key] === null || f[key] === undefined || f[key] === "" ) {
                delete f[key];
            } else if ( Array.isArray( f[key] ) && f[key].length === 0 ) {
                delete f[key];
            }
            const i = this.filters.find(fi => fi.name === key);
            if( i && i.type === 'date-range' ) {
                if( !(f[key].from && f[key].to) || f[key].from > f[key].to ) {
                    delete f[key];
                }
            }
        })

        return f;
    }

    public objectToQuery(obj: {[key:string]: any}): string {
        let params = new URLSearchParams();
        for(let key in obj){
            params.set(key, obj[key]) ;
        }

        return params.toString();
    }

    public setSelectOptions(name: string, options: Array<DataTableFilterOption>) {
        const filter = this.filters.find(f => f.name === name);
        if( filter ) {
            if( !filter.doNotSortOptions ) {
                options = options.sort( SortBy('label') );
            }
            filter.options = options;
        }
    }

    private restoreTableConfig(storeName: string): void {
        if( storeName ) {
            const key = AppInjector.getInjector().get(DATATABLE_STORE_PREFIX) + '-' + storeName;

            if( window.localStorage && localStorage.getItem(key) ) {
                const { columnsConfig, filterConfig, display_filters } = JSON.parse( localStorage.getItem(key) );

                if( columnsConfig ) this.displayCols = columnsConfig;
                if( filterConfig ) this._show_filters = filterConfig;
                if( display_filters !== undefined ) this._display_filters = display_filters;
            }
        }
    }

    private storeTableConfig(): void {
        if( this.storeName ) {
            const key = AppInjector.getInjector().get(DATATABLE_STORE_PREFIX) + '-' + this.storeName;

            const columnsConfig = this._displayCols;
            const filterConfig = this._show_filters;
            const display_filters = this._display_filters;

            if (window.localStorage) {
                localStorage.setItem(`${key}`, JSON.stringify({ columnsConfig, filterConfig, display_filters }));
            }
        }
    }

	getQueryParams(): PagingConditions {
		let { limit, offset, filters } = this.lastPagingEvent;
        // let sort = this.sort?.value;

        let copy = Object.assign({}, filters)

		if( !copy ) copy = {};

        // if( sort ) copy['sort'] = sort;

		return { limit, offset, ...copy as Object };
	}

    public isFocusRow(row: T): boolean
    {
        // None selected
        if( !this.focusRow ) { return false }

        if( this.isSelected )
        {
            return this.isSelected(row)(this.focusRow);
        }
        else
        {
            return this.focusRow === row;
        }
    }
}

export interface PagingConditions
{
	limit: number;

	offset: number;

	[key: string]: unknown;
}
